<template>
  <transition name="fade">
    <div class="modal-backdrop p-4 lg:p-0" @click="closeFilter">
      <div class="filter-container" @click.stop>
        <div class="filter-header">
          <div></div>
          <div class="filter-topic">{{ $t("filter.dt_fil") }}</div>
          <button
            class="close-btn close_modal text-light border-none"
            @click="closeFilter"
          >
            <b-icon-x scale="1.5" />
          </button>
        </div>
        <label class="mt-2">{{ $t("filter.cat") }}</label>
        <v-select
          :options="optionCategory"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_category"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("filter.sub_cat") }}</label>
        <v-select
          :options="optionSubCategory"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_sub_category"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("home.as_stat") }}</label>
        <v-select
          :options="statusData"
          label="name"
          :reduce="(option) => option.value"
          v-model="filterOptions.status"
          class="dropdown-style"
          :clearable="false"
        />
        <label class="mt-2">{{ $t("filter.build") }}</label>
        <v-select
          :options="optionBuilding"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_location_building"
          class="dropdown-style"
          :clearable="false"
        />
        <label class="mt-2">{{ $t("filter.fl") }}</label>
        <v-select
          :options="optionFloor"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_location_floor"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("filter.dep") }}</label>
        <v-select
          :options="optionDepartment"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_location_department"
          class="dropdown-style"
          :clearable="false"
        />

        <label class="mt-2">{{ $t("filter.zone") }}</label>
        <v-select
          :options="optionZone"
          label="label"
          :reduce="(option) => option.value"
          v-model="filterOptions.id_location_zone"
          class="dropdown-style"
          :clearable="false"
        />
        <div v-show="showAdvanceFilter">
          <hr />
          <label class="mt-2">{{ $t("filter.acc_note") }}</label>
          <v-select
            :options="optionNoteAccounting"
            :getOptionLabel="(option) => option.label"
            :reduce="(option) => option.value"
            v-model="filterOptions.note_accounting_id"
            class="dropdown-style"
            :clearable="false"
          />
          <ExpandHeight>
            <div v-if="filterOptions.note_accounting_id">
              <label class="mt-2">{{ $t("filter.asset_acc_code") }}</label>
              <v-select
                :options="optionSubNoteAccounting"
                :getOptionLabel="(option) => option.label"
                :reduce="(option) => option.value"
                v-model="filterOptions.sub_note_accounting_id"
                class="dropdown-style"
                :clearable="false"
              />
            </div>
          </ExpandHeight>
          <!-- <label class="mt-2">สถานะสินทรัพย์ทางบัญชี</label>
            <v-select
              :options="optionZone"
              label="label"
              :reduce="(option) => option.value"
              v-model="filterOptions.id_location_zone"
              class="dropdown-style"
              :clearable="false"
            /> -->
          <label class="mt-2">{{ $t("filter.date_start") }}</label>

          <div class="d-flex align-items-center w-100">
            <DatePicker
              class="w-100 form-border form-control"
              style="border: 1.25px solid white; margin: 0; padding: 0"
              v-model="filterOptions.dateUsed_start"
              :max-date="filterOptions.dateUsed_end"
              :popover="{ visibility: 'focus' }"
              :model-config="modelConfig"
              :masks="masks"
            >
              <template #default="{ togglePopover, inputValue, inputEvents }">
                <input
                  placeholder="dd/mm/yyyy"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="form-control form-border cal-icon"
                  @blur="togglePopover"
                  @click="togglePopover"
                />
              </template>
            </DatePicker>
            <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />
            <DatePicker
              v-model="filterOptions.dateUsed_end"
              :min-date="filterOptions.dateUsed_start"
              :popover="{ visibility: 'focus' }"
              class="w-100 form-border form-control"
              style="border: 1.25px solid white; margin: 0; padding: 0"
              :model-config="modelConfig"
              :masks="masks"
            >
              <template #default="{ togglePopover, inputValue, inputEvents }">
                <input
                  placeholder="dd/mm/yyyy"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="form-control form-border cal-icon"
                  @blur="togglePopover"
                  @click="togglePopover"
                />
              </template>
            </DatePicker>
          </div>
          <div v-if="!isRoleLower">
            <label class="mt-2">{{ $t("filter.useful_life") }}</label>
            <div class="d-flex align-items-center w-100">
              <vue-numeric
                class="w-100 form-control form-border"
                :placeholder="$t('filter.lowest')"
                v-model="filterOptions.useful_life_min"
              ></vue-numeric>

              <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

              <vue-numeric
                class="w-100 form-control form-border"
                :placeholder="$t('filter.highest')"
                v-model="filterOptions.useful_life_max"
              ></vue-numeric>
            </div>
            <!-- <label class="mt-2">{{ $t("filter.exp_date") }}</label>

              <div class="d-flex align-items-center w-100">
                <DatePicker
                  class="w-100 form-border form-control"
                  style="border: 1.25px solid white; margin: 0; padding: 0"
                  v-model="filterOptions.expire_date_min"
                  :max-date="filterOptions.expire_date_max"
                  :popover="{ visibility: 'focus' }"
                  :model-config="modelConfig"
                  :masks="masks"
                >
                  <template
                    #default="{ togglePopover, inputValue, inputEvents }"
                  >
                    <input
                      class="form-control form-border cal-icon"
                      placeholder="dd/mm/yyyy"
                      :value="inputValue"
                      v-on="inputEvents"
                      @blur="togglePopover"
                      @click="togglePopover"
                    />
                  </template>
                </DatePicker>
                <hr
                  style="width: 20%; margin: 0px 5px"
                  size="4"
                  color="black"
                />
                <DatePicker
                  class="w-100 form-border form-control"
                  style="border: 1.25px solid white; margin: 0; padding: 0"
                  v-model="filterOptions.expire_date_max"
                  :min-date="filterOptions.expire_date_min"
                  :popover="{ visibility: 'focus' }"
                  :model-config="modelConfig"
                  :masks="masks"
                >
                  <template
                    #default="{ togglePopover, inputValue, inputEvents }"
                  >
                    <input
                      class="form-control form-border cal-icon"
                      placeholder="dd/mm/yyyy"
                      :value="inputValue"
                      v-on="inputEvents"
                      @blur="togglePopover"
                      @click="togglePopover"
                    />
                  </template>
                </DatePicker>
              </div> -->
            <label class="mt-2">{{ $t("filter.purchase_price") }}</label>
            <div class="d-flex align-items-center w-100">
              <vue-numeric
                separator=","
                decimal-separator="."
                :precision="2"
                class="w-100 form-control form-border"
                :placeholder="$t('filter.lowest_price')"
                v-model="filterOptions.purchase_price_min"
              ></vue-numeric>

              <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

              <vue-numeric
                separator=","
                decimal-separator="."
                :precision="2"
                class="w-100 form-control form-border"
                :placeholder="$t('filter.highest_price')"
                v-model="filterOptions.purchase_price_max"
              ></vue-numeric>
            </div>

            <!-- <label class="mt-2" v-if="canUseCustom9">{{
              $t("filter.sum_depreciation_date")
            }}</label>
            <div class="d-flex align-items-center w-100" v-if="canUseCustom9">
              <DatePicker
                class="w-100 form-border form-control"
                style="border: 1.25px solid white; margin: 0; padding: 0"
                v-model="filterOptions.sum_depreciation_date_min"
                :max-date="filterOptions.sum_depreciation_date_max"
                :popover="{ visibility: 'focus' }"
                :model-config="modelConfig"
                :masks="masks"
              >
                <template #default="{ togglePopover, inputValue, inputEvents }">
                  <input
                    class="form-control form-border cal-icon"
                    placeholder="dd/mm/yyyy"
                    :value="inputValue"
                    v-on="inputEvents"
                    @blur="togglePopover"
                    @click="togglePopover"
                  />
                </template>
              </DatePicker>
              <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />
              <DatePicker
                class="w-100 form-border form-control"
                style="border: 1.25px solid white; margin: 0; padding: 0"
                v-model="filterOptions.sum_depreciation_date_max"
                :min-date="filterOptions.sum_depreciation_date_min"
                :popover="{ visibility: 'focus' }"
                :model-config="modelConfig"
                :masks="masks"
              >
                <template #default="{ togglePopover, inputValue, inputEvents }">
                  <input
                    class="form-control form-border cal-icon"
                    placeholder="dd/mm/yyyy"
                    :value="inputValue"
                    v-on="inputEvents"
                    @blur="togglePopover"
                    @click="togglePopover"
                  />
                </template>
              </DatePicker>
            </div> -->

            <label class="mt-2" v-if="$route.name === 'depreciation-asset'">{{
              $t("filter.scrap_value")
            }}</label>

            <div
              class="d-flex align-items-center w-100"
              v-if="$route.name === 'depreciation-asset'"
            >
              <vue-numeric
                separator=","
                decimal-separator="."
                :precision="2"
                class="w-100 form-control form-border"
                :placeholder="$t('filter.lowest_price')"
                v-model="filterOptions.scrap_value_min"
              ></vue-numeric>

              <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

              <vue-numeric
                separator=","
                decimal-separator="."
                :precision="2"
                class="w-100 form-control form-border"
                :placeholder="$t('filter.highest_price')"
                v-model="filterOptions.scrap_value_max"
              ></vue-numeric>
            </div>

            <label class="mt-2">{{ $t("asset.writeoff_date") }}</label>

            <div class="d-flex align-items-center w-100">
              <DatePicker
                class="w-100 form-border form-control"
                style="border: 1.25px solid white; margin: 0; padding: 0"
                v-model="filterOptions.write_off_date_min"
                :max-date="filterOptions.write_off_date_max"
                :popover="{ visibility: 'focus' }"
                :model-config="modelConfig"
                :masks="masks"
              >
                <template #default="{ togglePopover, inputValue, inputEvents }">
                  <input
                    class="form-control form-border cal-icon"
                    placeholder="dd/mm/yyyy"
                    :value="inputValue"
                    v-on="inputEvents"
                    @blur="togglePopover"
                    @click="togglePopover"
                  />
                </template>
              </DatePicker>
              <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />
              <DatePicker
                class="w-100 form-border form-control"
                style="border: 1.25px solid white; margin: 0; padding: 0"
                v-model="filterOptions.write_off_date_max"
                :min-date="filterOptions.write_off_date_min"
                :popover="{ visibility: 'focus' }"
                :model-config="modelConfig"
                :masks="masks"
              >
                <template #default="{ togglePopover, inputValue, inputEvents }">
                  <input
                    class="form-control form-border cal-icon"
                    placeholder="dd/mm/yyyy"
                    :value="inputValue"
                    v-on="inputEvents"
                    @blur="togglePopover"
                    @click="togglePopover"
                  />
                </template>
              </DatePicker>
            </div>
            <label class="mt-2">{{ $t("filter.writeoff_price") }}</label>

            <div class="d-flex align-items-center w-100">
              <vue-numeric
                separator=","
                decimal-separator="."
                :precision="2"
                class="form-control form-border"
                :placeholder="$t('filter.lowest_price')"
                v-model="filterOptions.write_off_amount_min"
              ></vue-numeric>

              <hr style="width: 20%; margin: 0px 5px" size="4" color="black" />

              <vue-numeric
                separator=","
                decimal-separator="."
                :precision="2"
                class="form-control form-border"
                :placeholder="$t('filter.highest_price')"
                v-model="filterOptions.write_off_amount_max"
              ></vue-numeric>
            </div>
          </div>
        </div>
        <div class="advance-filter-btn" @click="toggleAdvanceFilter">
          {{ textAdvanceBtn }}
        </div>
        <div
          class="d-flex justify-content-between footer-btn-container"
          style="height: 50px"
        >
          <button @click="handleclear" class="clear-btn">
            {{ $t("btn.clear") }}
          </button>
          <button @click="saveFilter" class="save-btn">
            {{ $t("btn.save") }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from "moment";
import { DatePicker } from "v-calendar";
import { mapGetters } from "vuex";
import ExpandHeight from "./Animation/expandHeight.vue";

export default {
  data() {
    return {
      masks: {
        input: "DD/MM/YYYY",
      },
      modelConfig: {
        type: "string",
      },
      statusData: [
        { name: this.$t("filter.all"), value: null },
        { name: this.$t("count.usable"), value: "available" },
        { name: this.$t("count.not_inuse"), value: "unavailable" },
        { name: this.$t("count.broke"), value: "broke" },
        { name: this.$t("count.repairing"), value: "maintain" },
        { name: this.$t("count.irrepar"), value: "cannot_repair" },
        { name: this.$t("filter.lost"), value: "lost" },
        { name: this.$t("asset.not_count"), value: "uncounting" },
        { name: this.$t("asset.not_reg"), value: "unregister" },
      ],
      filterOptions: {
        id_category: null,
        id_sub_category: null,
        status: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
        note_accounting_id: null,
        sub_note_accounting_id: null,
        dateUsed_start: null,
        dateUsed_end: null,
        useful_life_min: "",
        useful_life_max: "",
        expire_date_min: null,
        expire_date_max: null,
        purchase_price_min: "",
        purchase_price_max: "",
        sum_depreciation_date_min: null,
        sum_depreciation_date_max: null,
        scrap_value_min: "",
        scrap_value_max: "",
        write_off_date_min: null,
        write_off_date_max: null,
        write_off_amount_min: "",
        write_off_amount_max: "",
      },
      textAdvanceBtn: this.$t("filter.advance_filter"),
      showAdvanceFilter: false,
    };
  },
  methods: {
    moment,
    saveFilter() {
      this.$emit("filterOptions", this.filterOptions);
    },
    handleclear() {
      const emptyFilter = {
        id_category: null,
        id_sub_category: null,
        status: "ทั้งหมด",
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
        note_accounting_id: null,
        sub_note_accounting_id: null,
        dateUsed_start: null,
        dateUsed_end: null,
        useful_life_min: "",
        useful_life_max: "",
        expire_date_min: null,
        expire_date_max: null,
        purchase_price_min: "",
        purchase_price_max: "",
        sum_depreciation_date_min: null,
        sum_depreciation_date_max: null,
        scrap_value_min: "",
        scrap_value_max: "",
        write_off_date_min: null,
        write_off_date_max: null,
        write_off_amount_min: "",
        write_off_amount_max: "",
        is_clear_filter: true,
      };
      this.$emit("filterOptions", emptyFilter);
      this.filterOptions = emptyFilter;
      this.showAdvanceFilter = false;
    },
    closeFilter() {
      this.showAdvanceFilter = false;
      this.$emit("closeFilter", true);
    },
    toggleAdvanceFilter() {
      this.showAdvanceFilter = !this.showAdvanceFilter;
      if (this.showAdvanceFilter) {
        this.textAdvanceBtn = this.$t("filter.hide");
      } else {
        this.textAdvanceBtn = this.$t("filter.advance_filter");
      }
    },
  },
  computed: {
    ...mapGetters({
      optionChoice: "getfilterOption",
      isRoleLower: "isRoleLower",
      canUseCustom9: "canUseCustom9",
    }),

    optionCategory() {
      if (this.optionChoice.optionCategory) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionCategory.map((item) => {
              return { label: item.name, value: item.id_category };
            })
          ),
        ];
        return list;
      }
      return [{ label: this.$t("filter.all"), value: null }];
    },
    optionSubCategory() {
      if (this.filterOptions.id_category) {
        let list = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionCategory
            .find(
              (category) =>
                category.id_category === this.filterOptions.id_category
            )
            ?.sub_categories.map((item) => {
              return {
                label: item.name,
                value: item.id_sub_category,
              };
            }),
        ];
        return list;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },

    optionBuilding() {
      if (this.optionChoice.optionBuilding) {
        const buildingList = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice.optionBuilding.map((each) => {
            return {
              label: each.name,
              value: each.id_location_building,
            };
          }),
        ];
        // console.log("Building list: ", buildingList);
        return buildingList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionFloor() {
      if (this.filterOptions.id_location_building) {
        const floorList = [
          { label: this.$t("filter.all"), value: null },
          ...this.optionChoice?.optionBuilding
            .find(
              (item) =>
                item.id_location_building ===
                this.filterOptions.id_location_building
            )
            ?.location_floors.map((each) => {
              return { label: each.name, value: each.id_location_floor };
            }),
        ];
        return floorList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionDepartment() {
      if (this.optionChoice.optionDepartment) {
        let departmentList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionDepartment.map((item) => {
              return {
                label: item.name,
                value: item.id_location_department,
              };
            })
          ),
        ];
        return departmentList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionZone() {
      if (this.optionChoice.optionZone) {
        const zoneList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionZone.map((each) => {
              return {
                label: each.name,
                value: each.id_location_zone,
              };
            })
          ),
        ];
        // console.log("zone list: ", zoneList);
        return zoneList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionNoteAccounting() {
      if (this.optionChoice.optionsNoteAccounting) {
        const noteAccountingList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            this.optionChoice.optionsNoteAccounting.map((each) => {
              return {
                label: each.name,
                value: each.note_accounting_id,
              };
            })
          ),
        ];
        return noteAccountingList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
    optionSubNoteAccounting() {
      const noteAccouting = this.optionChoice?.optionsNoteAccounting?.find(
        (note_acc) => {
          return (
            note_acc.note_accounting_id ===
            this.filterOptions.note_accounting_id
          );
        }
      );
      if (noteAccouting) {
        const subNoteAccountingList = [
          { label: this.$t("filter.all"), value: null },
          ...new Set(
            noteAccouting.sub_note_accountings.map((each) => {
              return {
                label:
                  each.code_asset && each.name_asset
                    ? `${each.code_asset} - ${each.name_asset}`
                    : "-",
                value: each.sub_note_accounting_id,
              };
            })
          ),
        ];
        return subNoteAccountingList;
      } else {
        return [{ label: this.$t("filter.all"), value: null }];
      }
    },
  },
  components: { DatePicker, ExpandHeight },
  created() {
    this.$store.dispatch("loadOptionChoice");
  },
};
</script>

<style lang="scss" scoped>
div {
  margin-bottom: 12px !important;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  opacity: 1;
  height: 100%;
  display: flex;
  justify-content: center;
}

.filter-container {
  width: 80%;
  max-width: 400px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid black;
  overflow-y: auto;
  background-color: #ffffff;
  overflow-x: auto;
  max-height: 90vh;
  height: fit-content;
}

.filter-header {
  display: grid;
  grid-template-columns: 0.2fr 1fr 0.2fr;
}

.filter-topic {
  font-weight: bold;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.filter-block {
  width: 100vw;
  background: white;
  z-index: 2000;
  position: fixed;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.filter-block-inside {
  width: 90%;
  margin-top: 1rem;
  display: grid;
  gap: 1rem 1rem;
}

.filter-input-group {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#basic-addon1 {
  height: 45px;
  border: none;
  background-color: #e0e0e0;
}
select {
  padding-left: 0.5rem !important;
}

.filter {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-radius: 10px;

  background-color: white;
  margin: 0 !important;
}

.filter-box {
  height: 45px;
  border: 0px;
  background-color: #e0e0e0;
}

.input-group {
  margin: 0 auto;
  display: flex;
}

label {
  margin: 0 auto;
  display: flex;
  justify-self: start;

  padding-bottom: 0.3rem;
  margin-bottom: 0 !important;
  color: black;
}
input {
  width: 100%;

  border-radius: 10px;
  -webkit-appearance: none;
  padding: 0.4rem;
}

.dropdown-style {
  background-color: white;
  padding: 1px;
  border: solid 2px rgba(124, 124, 124, 1) !important;
  border-radius: 10px !important;
  ::v-deep .vs__dropdown-toggle {
    border: none;
    height: 30px;
  }
}

.dropdown-style ::v-deep .vs__search {
  background-color: white !important;
}

.advance-filter-btn {
  color: #0069d9;
  text-decoration: underline;
  margin: 20px 0px;
  cursor: pointer;
  text-align: center !important;
}
.clear-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 10px 80px;
  width: 100px;
  color: #0069d9 !important;
  border: 1px solid #0069d9;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.save-btn {
  background: none;
  color: inherit;
  border: 1px solid #0069d9;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 10px 80px;
  width: 100px;
  color: white;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: transparent linear-gradient(180deg, #007afe, #013f81) 0 0
    no-repeat padding-box;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media only screen and (max-width: 500px) {
  .filter-topic {
    font-size: 16.8px;
  }
  .filter-container {
    width: 95%;
    min-height: 96vh;
    padding: 10px;
  }

  .footer-btn-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px !important;
    // gap: 10px;
    margin: 5px 10px;
    // padding-bottom: 50px;
  }
}
.cal-icon {
  background-image: url("../assets/cal.svg");
  background-size: 15px;
  filter: grayscale(1);
  background-repeat: no-repeat !important;
  background-position: 96% 50% !important;
  padding-right: 1.5rem !important;
  // overflow: hidden;
  // padding-right: 40px !important;
}
</style>
